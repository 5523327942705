import { sample } from 'lodash';
import apiService from './index';

export default {
  bloggers: {
    async check(instanames) {
      const result = await apiService.post('/api/blogger_checker/checked_bloggers/check/', {
        instanames,
      });
      return result;
    },
  },
  groups: {
    async my(params) {
      const result = await apiService.get('/api/blogger_checker/check_groups/my/', { params });
      return result;
    },
    async sample() {
      const result = await apiService.get('/api/blogger_checker/check_groups/get_sample/');
      return result;
    },
  },
  xls: {
    async list(params) {
      const result = await apiService.get('/api/blogger_checker/xls/', {
        responseType: 'blob',
        params,
      });
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '@easyprbot провереные блогеры.xls'); // or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
};
