<template>
  <div class="mb-5 position-relative">
    <h1 class="mt-4 mb-3">Проверка блогеров</h1>
    <h5 class="mb-4 mt-1">
      По черным спискам, 30 крупным чатам активностей и рекомендациям к проверке на накрутку,
      отзывам, карточкам на сайте со статистикой и ценами
    </h5>
    <div class="hovering__pad p-4 pb-5 mb-5">
      <div
        v-if="!currentUser || !currentUser.services_subscriber"
        class="d-none d-md-flex justify-content-start align-items-center mb-3"
      >
        <p class="mr-4">Сервис доступен в полной версии сайта</p>
        <b-button href="https://sub.easyprbot.com/" target="_blank" variant="yellow"
          >Купить подписку за 2 990 ₽ в месяц</b-button
        >
      </div>
      <div class="d-flex flex-column-reverse flex-lg-row">
        <div class="checker-block mr-lg-4 mt-5 mt-lg-0">
          <h5 class="mb-3">Ники блогеров</h5>
          <p class="gray-text mb-3">
            Не более 100, по одному блогеру на строку. Без знаков препинания. Только ники или ссылки
            на блогеров списком.
          </p>
          <b-textarea
            ref="bloggerInp"
            id="bawudyg7hd-d,.dh"
            v-savedata
            :spellcheck="false"
            v-model="bloggers"
            style="border-radius: 5px !important"
            rows="10"
            class="overflow-auto mb-4"
            placeholder="knvlva
katenka.bloger
helli_help
nyam.foodblog
ms.cooll_
sashka_krlv
borka.tone
andrey.litvn
homeguied
beregova.babe"
          ></b-textarea>
          <div class="d-flex flex-column flex-xl-row justify-content-between">
            <b-button
              :disabled="!bloggerList.length || loading"
              @click="checkBloggers"
              :variant="!bloggerList.length ? 'outline-default' : 'yellow'"
              size="lg"
              >Проверить блогеров
              <b-spinner
                class="ml-1"
                variant="outline-default"
                style="width: 18px; height: 18px; opacity: 0.5"
                v-if="loading"
                size="sm"
              ></b-spinner>
            </b-button>
            <div class="mt-3 mt-xl-0">
              <div class="d-flex">
                <p class="fs-16" style="color: #6a7b83">
                  Будет потрачено проверок: {{ bloggerList.length }}
                </p>
                <img class="ml-1" src="@main/assets/img/svg/energy.svg" alt="" />
              </div>
              <p style="color: #6a7b83">На 1 блогера тратится 1 проверка</p>
            </div>
          </div>
        </div>
        <div class="checker-block ml-lg-5 mt-2 mt-lg-0">
          <div>
            <img
              class="mr-3 d-inline-block"
              style="width: 35px; height: 23px"
              src="@main/assets/img/icons/ut_icon.png"
            />
            <span style="font-size: 16px">Как работает проверка блогеров?</span>
          </div>
          <div class="mt-4 vid-checker">
            <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/UOfOiXPfK1E"
              allowfullscreen
            ></b-embed>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!currentUser || !currentUser.services_subscriber"
      class="hovering__pad d-lg-none my-5 p-4"
    >
      <p class="mb-3 text-center">Сервис доступен в полной версии сайта</p>
      <b-button
        href="https://sub.easyprbot.com/"
        target="_blank"
        size="lg"
        style="font-size: 15px !important"
        class="w-100"
        variant="yellow"
        >Купить подписку за 2 990 ₽ в месяц</b-button
      >
    </div>
    <div class="hovering__pad pb-3">
      <div
        class="px-4 pb-3 pt-2 d-flex flex-row justify-content-between align-items-center flex-wrap"
      >
        <h1 class="mt-2">{{ !example ? 'Результаты проверки' : 'Пример результатов проверки' }}</h1>
        <b-button @click="xls" class="mt-2" variant="outline-default"
          ><img src="@main/assets/img/svg/xcell.svg" class="d-inline-block mr-1" alt="" /> Скачать в
          .xls</b-button
        >
      </div>
      <b-table
        v-if="!isMobile"
        :fields="fields"
        :items="items"
        stacked="sm"
        bordered
        striped
        responsive
      >
        <template #cell(instaname)="data">
          <b-button :title="data.value" variant="link">{{ data.value }}</b-button>
        </template>
        <template #cell(activity_chats_member)="data">
          <div class="d-flex flex-row align-items-center">
            <img
              v-if="!data.value.bool"
              class="mr-2 mb-1"
              style="width: 14px; height: 14px"
              src="@main/assets/img/svg/done_icon.svg"
              alt=""
            />
            <img
              v-else
              class="mr-2 mb-1"
              style="width: 14px; height: 14px"
              src="@main/assets/img/svg/red-x.svg"
              alt=""
            />
            <span>{{ data.value.text }}</span>
          </div>
        </template>
        <template #head(activity_chats_member)="data">
          <div class="d-flex align-items-center">
            <p class="mr-2">{{ data.label }}</p>
            <DefaultTooltipMark
              v-b-tooltip.html="
                `Мы проверяем блогера на упоминание его постов в 30 крупных чатах активностей и в базе блогеров, рекомендованных к проверке на накрутку. <br><br>

В результате проверки вы получаете либо рекомендацию проверить блогера на накрутку на другом специализированном сервисе, либо сообщение о том, что у нас нет замечаний к блогеру. <br><br>

Рекомендация проверить блогера на накрутку не означает, что блогер 100% накручен или имеет искусственную активность в аккаунте.`
              "
            />
          </div>
        </template>
        <template #head(blacklisted)="data">
          <div class="d-flex align-items-center">
            <p class="mr-2">{{ data.label }}</p>
            <DefaultTooltipMark
              v-b-tooltip.html="
                'Проверка блогера на наличие в черном списке чата Инсталогии и в черном списке сайта easyprbot.com.'
              "
            />
          </div>
        </template>
        <template #cell(blacklisted)="data">
          <div class="d-flex flex-row align-items-center">
            <img
              v-if="!data.item.blacklisted_easyprbot && !data.item.blacklisted_instalogiya"
              class="mr-2 mb-1"
              style="width: 14px; height: 14px"
              src="@main/assets/img/svg/done_icon.svg"
              alt=""
            />
            <img
              v-else
              class="mr-2 mb-1"
              style="width: 14px; height: 14px"
              src="@main/assets/img/svg/red-x.svg"
              alt=""
            />
            <span>{{ data.value }}</span>
          </div>
        </template>
        <template #cell(reviews)="data">
          <p v-if="!data.item.reviews_count" class="gray-text">Нет отзывов на easyprbot.com</p>
          <div v-else class="d-flex flex-row justify-content-stretch">
            <b-button
              :to="{ name: 'reviews', query: { advertiser: data.item.instaname } }"
              class="px-3 checker-rev-bread"
              variant="link"
              >{{ data.value.reviews }}</b-button
            >
            <div
              style="border-left: 1px solid #b4bdc1; border-right: 1px solid #b4bdc1"
              class="rev-ppo-checker px-3 checker-rev-bread position-relative"
            >
              {{ data.value.ppo_range }}
              <span
                style="font-size: 10.6px; min-width: 90px; top: 20px; left: 14px"
                class="position-absolute gray-text"
                >за подписч.</span
              >
            </div>
            <div class="px-3 checker-rev-bread">
              {{ data.value.rate }}
              <img class="rev_star" src="@main/assets/img/svg/star.svg" alt="" />
            </div>
          </div>
        </template>
        <template #cell(blogger)="data">
          <p v-if="!data.value" class="gray-text">Нет на сайте</p>
          <b-button
            v-else
            variant="link"
            @click="
              () => {
                toggleStatisticCardModal({
                  open: true,
                  context: { instaname: data.item.instaname },
                });
              }
            "
            >Показать</b-button
          >
        </template>
      </b-table>
      <div class="mob-checker-table" v-else>
        <DefaultMobileTable
          stripped
          bordered
          blockrender
          :item="item"
          :fields="fields"
          v-for="item in items"
          :key="item.key"
        >
          <template #cell(datetime_checked)-custom="data"
            ><p class="text-right gray-text fs-12 w-100">
              Дата проверки: {{ data.value }}
            </p></template
          >
          <template #cell(instaname)-custom="data"
            ><div>
              <b-button variant="link">{{ data.value }}</b-button>
            </div></template
          >
          <template #head(activity_chats_member)="data"
            ><div class="d-flex mt-3 align-items-center">
              <p class="gray-text">{{ data.label }}</p>
            </div>
          </template>
          <template #cell(activity_chats_member)="data">
            <div class="d-flex flex-row align-items-center mt-2">
              <img
                v-if="!data.value.bool"
                class="mr-2 mb-1"
                style="width: 14px; height: 14px"
                src="@main/assets/img/svg/done_icon.svg"
                alt=""
              />
              <img
                v-else
                class="mr-2 mb-1"
                style="width: 14px; height: 14px"
                src="@main/assets/img/svg/red-x.svg"
                alt=""
              />
              <span>{{ data.value.text }}</span>
            </div></template
          >
          <template #head(blacklisted)="data"
            ><div class="d-flex mt-3 align-items-center">
              <p class="gray-text">{{ data.label }}</p>
            </div>
          </template>
          <template #head(reviews)="data"
            ><div class="mt-3">
              <p class="gray-text">{{ data.label }}</p>
            </div>
          </template>
          <template #cell(blacklisted)="data">
            <div class="d-flex flex-row align-items-center mt-2">
              <img
                v-if="!data.item.blacklisted_easyprbot && !data.item.blacklisted_instalogiya"
                class="mr-2 mb-1"
                style="width: 14px; height: 14px"
                src="@main/assets/img/svg/done_icon.svg"
                alt=""
              />
              <img
                v-else
                class="mr-2 mb-1"
                style="width: 14px; height: 14px"
                src="@main/assets/img/svg/red-x.svg"
                alt=""
              />
              <span>{{ data.value }}</span>
            </div></template
          >
          <template #cell(reviews)="data">
            <div class="mt-2">
              <p v-if="!data.item.reviews_count" class="gray-text">Нет отзывов на easyprbot.com</p>
              <div v-else class="d-flex flex-row justify-content-stretch">
                <b-button class="checker-rev-bread" variant="link">{{
                  data.value.reviews
                }}</b-button>
                <div
                  style="border-left: 1px solid #b4bdc1; border-right: 1px solid #b4bdc1"
                  class="rev-ppo-checker checker-rev-bread position-relative"
                >
                  {{ data.value.ppo_range }}
                  <span
                    class="gray-text"
                    style="font-size: 10.6px; min-width: 90px; top: 20px; left: 14px"
                    >за подписч.</span
                  >
                </div>
                <div class="checker-rev-bread">
                  {{ data.value.rate }}
                  <img class="rev_star" src="@main/assets/img/svg/star.svg" alt="" />
                </div>
              </div></div
          ></template>
          <template #cell(blogger)-custom="data">
            <b-button
              class="w-100 mt-4 mb-2"
              :disabled="!data.value"
              variant="outline-default"
              @click="
                () => {
                  toggleStatisticCardModal({
                    open: true,
                    context: { instaname: data.item.instaname },
                  });
                }
              "
            >
              {{
                data.value
                  ? 'Открыть карточку блогера на Easyprbot.com'
                  : 'Нет карточки блогера на Easyprbot.com'
              }}</b-button
            >
          </template>
        </DefaultMobileTable>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { mapMutations, mapState, mapActions } from 'vuex';
import DefaultMobileTable from '@main/components/reusable/tables/DefaultMobileTable.vue';
import DefaultTooltipMark from '@main/components/reusable/tooltips/marks/DefaultTooltipMark.vue';
import bloggerChecker from '@main/api/bloggerChecker';
import literal from '@main/func/literal';
import wrappers from '@main/func/wrappers';
import {
  RequiresAuth,
  RequiresBloggerProfile,
  RequiresEnergy,
  RequiresSub,
  pipelineFactory,
} from '@main/func/pipeline';
import moment from 'moment';
import bloggers from '../../api/bloggers';

const defField = { class: 'align-middle default-checker-cell py-4', thClass: 'checker-th' };

export default {
  components: {
    DefaultMobileTable,
    DefaultTooltipMark,
  },
  metaInfo: {
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Проверьте в 2 клика по черным спискам, 30 чатам активностей, отзывам, статистике и рекомендациям к проверке на накрутку. Можно загрузить блогеров списком и получить информацию по каждому.',
      },
    ],
  },
  data: () => ({
    bloggers: '',
    items: null,
    example: false,
    isMobile,
    loading: false,
    params: {
      page: 1,
      page_size: 20,
    },
  }),
  computed: {
    ...mapState(['currentUser']),
    fields() {
      return [
        {
          key: 'datetime_checked',
          label: 'Дата проверки',
          formatter(val) {
            return moment(val).format('DD.MM.YY');
          },
          ...defField,
          tdClass: 'checker__date--field',
          type: 'other',
        },
        { key: 'instaname', label: 'Ник блогера', ...defField, type: 'other' },
        {
          key: 'activity_chats_member',
          label: 'Проверка по 30 чатам активностей и рекомендациям для проверки на накрутку',
          ...defField,
          formatter(value, key, item) {
            return {
              text: value ? 'Рекомендуем проверить на накрутку' : 'Нет замечаний',
              bool: value,
            };
          },
        },
        {
          key: 'blacklisted',
          label: 'Чёрные списки',
          formatter(value, key, item) {
            let text = '';
            if (item.blacklisted_easyprbot && item.blacklisted_instalogiya) {
              text = 'ЧС Инсталогии и Easyprbot';
            } else if (item.blacklisted_easyprbot) {
              text = 'Есть в ЧС Easyprbot';
            } else if (item.blacklisted_instalogiya) {
              text = 'Был в ЧС Инсталогии';
            } else {
              text = 'Нет в чёрных списках';
            }
            return text;
          },
          ...defField,
        },
        {
          key: 'reviews',
          label: 'Отзывы',
          ...defField,
          class: 'rev_-cell-checker align-middle',
          formatter(value, key, item) {
            return {
              reviews: `${item.reviews_count} ${literal.declension(item.reviews_count, 'отзыв')}`,
              ppo_range:
                item.reviews_count > 1
                  ? `${item.reviews_min_ppo} — ${item.reviews_max_ppo} ₽`
                  : `${item.reviews_min_ppo} ₽`,
              rate: Number(item.reviews_avg_rate).toFixed(1),
            };
          },
        },
        {
          key: 'blogger',
          label: 'Карточка блогера на Easyprbot.com',
          ...defField,
          tdClass: 'checker__blogger-statistic--field align-middle',
          class: 'stat_-cell-checker align-middle',
          type: 'other',
        },
      ];
    },
    bloggerList() {
      return this.bloggers
        .split('\n')
        .filter((blogger) => !!blogger)
        .map((blogger) => {
          const name = blogger.replaceAll(' ', '');
          const match = name.match(
            /(https?:\/\/)?(www\.)?(instagram\.com\/?)?@?(?<instaname>[a-zA-Z0-9._]+)\/?/
          );
          if (match) {
            return match.groups.instaname;
          }
          return '';
        })
        .filter((val) => val);
    },
  },
  methods: {
    ...mapMutations(['toggleSignIn']),
    ...mapMutations('payments', ['toggleEnergyPriceModal', 'toggleSubscriptionPriceModal']),
    ...mapActions(['loadUser']),
    ...mapMutations('popups', ['toggleStatisticCardModal']),
    async updateData() {
      let res;
      try {
        res = await bloggerChecker.groups.my(this.params);
        if (!res || !res.checked_bloggers.length) {
          res = await bloggerChecker.groups.sample();
        }
      } catch (e) {
        res = await bloggerChecker.groups.sample();
      }
      this.items = res.checked_bloggers;
      this.example = res.example;
    },
    checkBloggers() {
      const handler = pipelineFactory([RequiresAuth, RequiresSub, RequiresEnergy]);
      handler.handle(
        // eslint-disable-next-line func-names
        () => {
          if (
            !this.currentUser.profile.energy ||
            this.bloggerList.length > this.currentUser.profile.energy
          ) {
            this.toggleEnergyPriceModal(true);
            return;
          }

          this.loading = true;
          bloggerChecker.bloggers
            .check(this.bloggerList)
            .then((res) => {
              if (!this.items || this.example) {
                this.items = res;
                this.example = false;
                return;
              }
              this.items.unshift(...res);
              this.loadUser();
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => {
              this.loading = false;
            });
        },
        { amount: this.bloggerList.length }
      );
    },
    async xls() {
      if (!this.currentUser) {
        this.toggleSignIn(true);
        return;
      }
      const res = await bloggerChecker.xls.list();
    },
  },
  async mounted() {
    this.bloggers = document.getElementById('bawudyg7hd-d,.dh').value;
    await this.updateData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table.b-table.b-table-stacked > tbody > tr > ::before {
  width: 100% !important;
  text-align: left !important;
}
::v-deep .tooltip {
  position: absolute !important;
  width: 230px !important;
  left: 0px;
}
::v-deep .arrow {
  display: none !important;
}
@media (min-width: 999px) {
  .checker-block {
    width: 50% !important;
  }
  .vid-checker {
    max-width: 605px !important;
  }
  ::v-deep .tooltip-inner {
    min-width: 500px !important;
  }
}
::v-deep .tooltip-inner {
  position: absolute;
  min-width: 230px;
  font-size: 12.7px !important;
  background-color: white !important;
  text-align: left !important;
  color: black !important;
  opacity: 1 !important;
  border: 1px solid #3897f0;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1);
  border-radius: 5px;
  right: -83px;
}
</style>

<style lang="scss">
.stat_-cell-checker {
  max-width: 140px;
}
.rev_-cell-checker {
  min-width: 290px;
}
</style>
